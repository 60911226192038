import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'volvo-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements OnInit {
  account: AccountInfo = null;
  token: string = null;
   apiKey : string = 'j9tk1ZQARDeJrGnmvImVfQ';
  //elastic:WewkYVx1aVT6SH5ZZMoZhxre@
   username = 'elastic';
 password = 'WewkYVx1aVT6SH5ZZMoZhxre';
 kibanaUrl: string;
 //kibanaUrl: string = `https://${this.username}:${this.password}@volvoselected-elk-prod.kb.westeurope.azure.elastic-cloud.com/app/dashboards#/view/444ee5cd-c558-471c-a73f-3ec645c58164?embed=true`;
  //kibanaUrl: string = `https://volvoselected-elk-prod.kb.westeurope.azure.elastic-cloud.com/app/dashboards#/view/444ee5cd-c558-471c-a73f-3ec645c58164?embed=true&auth=${this.apiKey}`;;
  safeKibanaUrl: SafeResourceUrl;
  constructor(private msalService: MsalService, private sanitizer: DomSanitizer
  ) {
    
  }
  ngOnInit(): void {
    
    this.account = this.msalService.instance.getActiveAccount();
   // this.getAccessToken();
  }

  name() {
    let text = this.account.name;
    let cleanedText = text.replace(/\s*\([^)]*\)/g, '');
    return cleanedText;
  }

  getAccessToken(): void {
  const account = this.msalService.instance.getActiveAccount();
  if (account) {
    this.msalService.acquireTokenSilent({
      scopes: ['user.read'], // Replace with your required scopes
      account: account,
    }).subscribe({
      next: (tokenResponse) => {
        console.log('Access Token:', tokenResponse.accessToken);
        this.token = tokenResponse.accessToken;
        this.kibanaUrl = `https://volvoselected-elk-prod.kb.westeurope.azure.elastic-cloud.com/app/dashboards#/view/444ee5cd-c558-471c-a73f-3ec645c58164?auth_provider_hint=oidc&token=${this.token}`;

        console.log(this.kibanaUrl);
        this.safeKibanaUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.kibanaUrl);
      },
      error: (error) => {
        console.error('Error acquiring token:', error);
      }
    });
  }

  }
}
