<section *ngIf="account" class="admin">
  <div class="admin-adminDiv">
    <div class="admin-title">
      <span>Congratulations {{name()}}, you are logged in. </span>
    </div>
    <div class="admin-text admin-display">
      <span class="admin-text-medium">Here be some medium text</span>
      <span>some span 1

        <!-- <div>
          <h2>Embedded Kibana Dashboard</h2>
          <iframe [src]="safeKibanaUrl" width="100%" height="600px" frameborder="0" sandbox="allow-scripts allow-same-origin"></iframe>
        </div> -->
      </span>
      <span>some span 2</span>
      <span>some span 3</span>
    </div>
    <div class="admin-text">
      <span class="admin-text-medium">Some phone number to someone:
      </span><span>01234 56789</span>
    </div>
    <div class="admin-text">
      <span class="admin-text-medium">Here be some mail form perhaps: </span> <span><a
          href="mailto:admins@awesome-used-vehicles.something">admins@some-cool-used-trucks-people.something</a></span>
    </div>
    <div class="admin-sectionDiv">
      <div class="admin-text">
        <span>Here be some admin text of some kind</span>
      </div>
      <div class="admin-text">
        <span>Here be some other admin text of the same kind, but better. </span>
        <a href="https://www.financial-ombudsman.org.uk" target="_blank"
          class="admin-text-textdecoration">www.financial-ombudsman.org.uk</a>
      </div>
    </div>
  </div>

</section>

<volvo-footer></volvo-footer>